import React from 'react'
import { Divider, Row, Typography } from 'antd'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'

import { EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth'
import { useAuth } from 'reactfire'

interface Callbacks {
  signInSuccessWithAuthResult?(
    // tslint:disable-next-line:no-any firebase dependency not available.
    authResult: any,
    redirectUrl?: string
  ): boolean
  signInFailure?(error: firebaseui.auth.AuthUIError): Promise<void>
  uiShown?(): void
}

interface SignInProps {
  callbacks?: Callbacks
}

const SignInModal: React.FunctionComponent<SignInProps> = ({ callbacks }) => {

  const auth = useAuth()

  // Configure FirebaseUI.
  const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: window.location.pathname,
    signInOptions: [
      {
        provider: EmailAuthProvider.PROVIDER_ID,
        fullLabel: 'Continue with email'
      },
      {
        provider: GoogleAuthProvider.PROVIDER_ID,
        fullLabel: 'Continue with Google'
      },
    ],
    callbacks,
  }

  return (
    <>
      <Row justify="center">
        <img src="/logo.svg" alt="Pollination logo: a flower with multi-color petals." height="84px" />
      </Row>
      <Row justify="center">
        <Typography.Title>Welcome!</Typography.Title>
      </Row>
      <Row justify="center">
        <Row justify='center' style={{ flexDirection: 'column' }}>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
          <div style={{ marginTop: 24, maxWidth: 250 }}>
            <Typography.Text >
              By clicking Continue with email or Continue with Google, you agree to our
              <a href='https://www.pollination.solutions/terms-of-service' target={'_blank'} rel='noreferrer'>{' Terms of Service.'}</a>
            </Typography.Text>
          </div>
        </Row>
        <Divider />
      </Row>
    </>
  )
}

export default SignInModal
